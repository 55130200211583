export const convertDateToIsoString = (string) => {
  // console.log("string", string);
  if (string === undefined) {
    return "";
  }
  let date = new Date(string);
  if (date instanceof Date && !isNaN(date)) {
    return date.toISOString();
  }
  return "";
};
