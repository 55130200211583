import BorrowerLoanAppContactInfo from "./BorrowerLoanAppContactInfo";
import BorrowerLoanAppExperience from "./BorrowerLoanAppExperience";
import BorrowerLoanAppLiquidity from "./BorrowerLoanAppLiquidity";
import BorrowerLoanAppBackground from "./BorrowerLoanAppBackground";
import BorrowerLoanAppConfirmation from "./BorrowerLoanAppConfirmation";
import BorrowerLoanAppProduct from "./BorrowerLoanAppProduct";
import BorrowerLoanAppSubjectProperty from "./BorrowerLoanAppSubjectProperty";
import BorrowerLoanAppLoan from "./BorrowerLoanAppLoan";

export const borrowerLoanApplicationFormSteps = [
  {
    stepName: "Borrower",
    stepComponent: <BorrowerLoanAppContactInfo />,
    // pageHeader: "Borrower Details and Contact Info",
  },
  {
    stepName: "Experience",
    stepComponent: <BorrowerLoanAppExperience />,
    // pageHeader: "Borrower Experience",
  },
  {
    stepName: "Liquidity",
    stepComponent: <BorrowerLoanAppLiquidity />,
    // pageHeader: "Borrower Liquidity",
  },
  {
    stepName: "Background",
    stepComponent: <BorrowerLoanAppBackground />,
    // pageHeader: "Borrower Background",
  },
  {
    stepName: "Product",
    stepComponent: <BorrowerLoanAppProduct />,
    // pageHeader: "Product Details",
  },
  {
    stepName: "Property",
    stepComponent: <BorrowerLoanAppSubjectProperty />,
    // pageHeader: "Subject Property Information",
  },
  {
    stepName: "Loan",
    stepComponent: <BorrowerLoanAppLoan />,
    // pageHeader: "Loan Details",
  },
  {
    stepName: "Confirmation",
    stepComponent: <BorrowerLoanAppConfirmation />,
    // pageHeader: "Legal Agreement & Confirmation",
  },
];
