export const fieldNames = {
  borrowerTrackRecordFiles: "borrowerTrackRecordFiles",
  borrowerRentalPropertiesTotalNumber: "borrowerRentalPropertiesTotalNumber",
  borrowerRentalPropertiesTotalValue: "borrowerRentalPropertiesTotalValue",
  ironId: "ironId",
  dob: "dob",
  dobDay: "dobDay",
  dobMonth: "dobMonth",
  dobYear: "dobYear",
  firstName: "firstName",
  middleName: "middleName",
  lastName: "lastName",
  password: "password",
  phoneNumber: "phoneNumber",
  emailAddress: "emailAddress",
  loanType: "loanType",
  loanPurpose: "loanPurpose",
  isSelfManagingProperty: "isSelfManagingProperty",
  loanSalesperson: "loanSalesperson",
  assignedSalesperson: "assignedSalesperson",
  loanBusinessPlan: "loanBusinessPlan",
  primaryAddressFull: "primaryAddressFull",
  primaryAddressStreetName: "primaryAddressStreetName",
  primaryAddressStreetNumber: "primaryAddressStreetNumber",
  primaryAddressCity: "primaryAddressCity",
  primaryAddressState: "primaryAddressState",
  primaryAddressZip: "primaryAddressZip",
  primaryAddressCountry: "primaryAddressCountry",
  applicationIsForMultipleProperties: "applicationIsForMultipleProperties",
  applicationPropertyAddress: "applicationPropertyAddress",
  numberOfSubjectProperties: "numberOfSubjectProperties",
  applicationPropertyOccupancy: "applicationPropertyOccupancy",
  applicationPropertyRequestedLoanAmount:
    "applicationPropertyRequestedLoanAmount",
  applicationPropertyPurchasePrice: "applicationPropertyPurchasePrice",
  applicationPropertyPurchaseDate: "applicationPropertyPurchaseDate",
  applicationPropertyValueAppraisedPreRenovation:
    "applicationPropertyValueAppraisedPreRenovation",
  applicationPropertyValuePostRenovation:
    "applicationPropertyValuePostRenovation",
  applicationPropertyRenovationBudget: "applicationPropertyRenovationBudget",
  applicationPropertyMonthlyRent: "applicationPropertyMonthlyRent",

  propertyHasExistingDebt: "propertyHasExistingDebt",
  totalUnpaidPrincipal: "totalUnpaidPrincipal",
  isCurrentLoanInDefault: "isCurrentLoanInDefault",
  propertyPurchasePrice: "propertyPurchasePrice",
  propertyPurchaseDate: "propertyPurchaseDate",
  hasUnfinishedWorkFromPriorLoan: "hasUnfinishedWorkFromPriorLoan",
  hasPropertyViolations: "hasPropertyViolations",
  hasPendingBorrowerLawsuits: "hasPendingBorrowerLawsuits",
  hasPropertyEnviornmentalConditions: "hasPropertyEnviornmentalConditions",
  citizenshipStatus: "citizenshipStatus",
  ssn: "ssn",
  idDocType: "idDocType",
  idDocDateOfIssue: "idDocDateOfIssue",
  idDocNumber: "idDocNumber",
  idDocExpiration: "idDocExpiration",
  idNumber: "idNumber",
  isLicensedGc: "isLicensedGc",
  isMarried: "isMarried",
  estimatedCreditScore: "estimatedCreditScore",
  actualCreditScore: "actualCreditScore",
  flipsInLastThreeYears: "flipsInLastThreeYears",
  flipsInLastOneYear: "flipsInLastOneYear",
  maritalStatus: "maritalStatus",
  photoId: "photoId",
  signature: "signature",
  submissionDate: "submissionDate",
  yearsExperienceProducingProperties: "yearsExperienceProducingProperties",
  groundUpConstructionProjectsInLastThreeYears:
    "groundUpConstructionProjectsInLastThreeYears",
  numberOfPropertiesOwnedInLastThreeYears:
    "numberOfPropertiesOwnedInLastThreeYears",
  hasPurchasedPropertiesAtAuction: "hasPurchasedPropertiesAtAuction",
  hasPurchasedPropertiesAtAuctionWithinThreeYears:
    "hasPurchasedPropertiesAtAuctionWithinThreeYears",
  numOfpropertiesPurchasedAtAuction: "numOfpropertiesPurchasedAtAuction",
  hasTwoPlusYearsExperienceOfRealEstate:
    "hasTwoPlusYearsExperienceOfRealEstate",
  statesWithFinanceExperienceAndInterest:
    "statesWithFinanceExperienceAndInterest",
  liquidityPersonalBankAccounts: "liquidityPersonalBankAccounts",
  liquidityBusinessBankAccounts: "liquidityBusinessBankAccounts",
  liquidityIraBrokerageAccounts: "liquidityIraBrokerageAccounts",
  liquidityCashValueOfLifeInsurance: "liquidityCashValueOfLifeInsurance",
  liquidityOther: "liquidityOther",
  liquidityOtherDocuments: "liquidityOtherDocuments",
  //
  loanProductType: "loanProductType",
  loanPropertyHasMoreThanFourUnits: "loanPropertyHasMoreThanFourUnits",
  hasWorkedWithFlatironInPast: "hasWorkedWithFlatironInPast",
  previousFlatironContact: "previousFlatironContact",
  ///Deal Submission
  ficoMidScore: "ficoMidScore",
  creditReportDate: "creditReportDate",
  borrowerHasMortgageLates: "borrowerHasMortgageLates",
  thirtyDayMortgageLates: "thirtyDayMortgageLates",
  sixtyDayMortgageLates: "sixtyDayMortgageLates",
  sufficientTradeLines: "sufficientTradeLines",

  hasBackgroundFlags: "hasBackgroundFlags",
  backgroundFlags: "backgroundFlags",
  totalCashLiquidity: "totalCashLiquidity",
  otherSourcesLiquidity: "otherSourcesLiquidity",
  totalYearsExperience: "totalYearsExperience",
  experienceInlastTwoYears: "experienceInlastTwoYears",
  incomeProducingProperties: "incomeProducingProperties",
  numberOfFlips: "numberOfFlips",
  numberOfGroundUpProjects: "numberOfGroundUpProjects",
  isExperienced: "isExperienced",
  entityName: "entityName",
  entityType: "entityType",
  entityStateOfOrganization: "entityStateOfOrganization",
  entityOrganizationDate: "entityOrganizationDate",
  einNumber: "einNumber",
  percentOwnership: "percentOwnership",
  name: "name",
  entityMembers: "entityMembers",
  entityManagers: "entityManagers",
  subjectProperties: "subjectProperties",
  borrowers: "borrowers",
  guarantors: "guarantors",
  ein: "ein",
  articlesOfIncorporation: "articlesOfIncorporation",
  certificateOfGoodStanding: "certificateOfGoodStanding",
  foreignEntityDocument: "foreignEntityDocument",
  operatingAgreement: "operatingAgreement",
  closingDate: "closingDate",
  moneyInDate: "moneyInDate",
  termsAcceptedDate: "termsAcceptedDate",
  isFloatingRateLoan: "isFloatingRateLoan",
  floatingRateLoanBaseRate: "floatingRateLoanBaseRate",
  floatingRateLoanFloorRate: "floatingRateLoanFloorRate",
  floatingRateLoanCapRate: "floatingRateLoanCapRate",
  floatingRateLoanRateIndex: "floatingRateLoanRateIndex",
  floatingRateLoanRateMargin: "floatingRateLoanRateMargin",
  closingScheduled: "closingScheduled",
  totalLoanAmount: "totalLoanAmount",
  initialLoanAmount: "initialLoanAmount",
  constructionRenovationBudget: "constructionRenovationBudget",
  loanToPurchasePrice: "loanToPurchasePrice",
  sellersConcession: "sellersConcession",
  loanToAsIsValue: "loanToAsIsValue",
  loanToAfterRenovationValue: "loanToAfterRenovationValue",
  interestRate: "interestRate",
  originationPoints: "originationPoints",
  originationValue: "originationValue",
  discountPoints: "discountPoints",
  feeName: "feeName",
  feeValue: "feeValue",
  fees: "fees",
  address: "address",
  streetNumber: "streetNumber",
  streetName: "streetName",
  city: "city",
  state: "state",
  zip: "zip",
  country: "country",
  numberOfUnits: "numberOfUnits",
  propertyType: "propertyType",
  squareFootage: "squareFootage",
  asIsValue: "asIsValue",
  secondaryAsIsValue: "secondaryAsIsValue",
  asIsValueDate: "asIsValueDate",
  requestedLoanAmount: "requestedLoanAmount",
  secondaryArv: "secondaryArv",
  purchasePriceFees: "purchasePriceFees",
  purchaseDate: "purchaseDate",
  totalBudget: "totalBudget",
  financedBudget: "financedBudget",
  propertyCondition: "propertyCondition",
  scopeOfWork: "scopeOfWork",
  totalSquareFootage: "totalSquareFootage",
  postRehabSquareFootage: "postRehabSquareFootage",
  annualPropertyTaxes: "annualPropertyTaxes",
  annualHazardInsurance: "annualHazardInsurance",
  annualFloodInsurance: "annualFloodInsurance",
  annualHoaFees: "annualHoaFees",
  annualPropertyManagementFees: "annualPropertyManagementFees",
  units: "units",
  loanCreator: "loanCreator",
  loanAssignedTo: "loanAssignedTo",
  loanCreatedDate: "loanCreatedDate",
  loanStatus: "loanStatus",
  loanCloseDate: "loanCloseDate",
  loanSubjectName: "loanSubjectName",
  hasHubspotId: "hasHubspotId",
  hubspotDealId: "hubspotDealId",
  hubspotPipeline: "hubspotPipeline",
  hubspotRelationshipType: "hubspotRelationshipType",
  hubspotOriginalDealSource: "hubspot",
  hubspotSourceCategory: "hubspotSourceCategory",
  hubspot: "hubspot",
  sizer: "sizer",
  teamstandThread: "teamstandThread",
  moneyInExcelFile: "moneyInExcelFile",
  salespersonEmail: "salespersonEmail",
  appraisalFeesCollected: "appraisalFeesCollected",
  applicationFeesCollected: "applicationFeesCollected",
  moneyInEnviornmentalFeesCollected: "moneyInEnviornmentalFeesCollected",
  moneyInCdaFeesCollected: "moneyInCdaFeesCollected",
  moneyInFeasabilityFeesCollected: "moneyInFeasabilityFeesCollected",
  moneyInOtherFeesCollected: "moneyInOtherFeesCollected",
  borrowerName: "borrowerName",
  borrowerEntity: "borrowerEntity",
  paymentType: "paymentType",
  moneyInVerifiedBy: "moneyInVerifiedBy",
  numberOfAppraisalsToOrder: "numberOfAppraisalsToOrder",
  numberOfTitlesToOrder: "numberOfTitlesToOrder",
  salesperson: "salesperson",
  refunded: "refunded",
  createTeamstandThread: "createTeamstandThread",
  moneyInVerified: "moneyInVerified",
  bankStatementRawData: "bankStatementRawData",
  product: "product",
  loan: "loan",
  wholesale: "wholesale",
  submittedBy: "submittedBy",
  nolocoCommentsCollection: "nolocoCommentsCollection",
  rushOrderIsRequired: "rushOrderIsRequired",
  amcPreference: "amcPreference",
  amcPreferenceOther: "amcPreferenceOther",
  marketRent: "marketRent",
  marketRentAddendumIsRequired: "marketRentAddendumIsRequired",
  hasCopyOfPurchaseContract: "hasCopyOfPurchaseContract",
  needAppraisalOrder: "needAppraisalOrder",
  needTitleOrder: "needTitleOrder",
  appraisalArv: "appraisalArv",
  appraisalDate: "appraisalDate",
  appraisalArvDate: "appraisalArvDate",
  appraisalAsIsValue: "appraisalAsIsValue",
  appraisalPointOfContact: "appraisalPointOfContact",
  appraisalPointOfContactEmailAddress: "appraisalPointOfContactEmailAddress",
  appraisalPointOfContactPhoneNumber: "appraisalPointOfContactPhoneNumber",
  appraisalPointOfContactBestTimeToContact:
    "appraisalPointOfContactBestTimeToContact",
  appraisalPointOfContactNotes: "appraisalPointOfContactNotes",
  rehabBudgetAmount: "rehabBudgetAmount",
  arvIsRequired: "arvIsRequired",
  moneyInRequirements: "moneyInRequirements",
  willOverwriteExistingData: "willOverwriteExistingData",
  //Invoice
  invoiceType: "invoiceType",
  invoicePaymentType: "invoicePaymentType",
  invoiceIncludeDetailedWireInstructions:
    "invoiceIncludeDetailedWireInstructions",
  invoiceDetailedWireInstructions: "invoiceDetailedWireInstructions",
  invoiceDate: "invoiceDate",
  invoiceVendorName: "invoiceVendorName",
  invoiceDescription: "invoiceDescription",
  invoiceAmount: "invoiceAmount",
  invoiceRequestedDueDate: "invoiceRequestedDueDate",
  invoiceAttachment: "invoiceAttachment",
  invoiceNumber: "invoiceNumber",
};
