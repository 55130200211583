export const returnQueryStringParametersInObject = (url) => {
  // Split the URL into its components
  const urlParts = url.split("?");

  // Get the query string
  const queryString = urlParts[1];

  // If there is no query string, return an empty object
  if (!queryString) {
    return {};
  }

  // Create a new object to store the parameters
  const params = {};

  // Split the query string into key-value pairs
  const keyValuePairs = queryString.split("&");

  // Loop through the key-value pairs
  for (const keyValuePair of keyValuePairs) {
    // Split the key-value pair into its components
    const keyValuePairParts = keyValuePair.split("=");

    // Get the key
    let key = keyValuePairParts[0];

    // Get the value
    let value = keyValuePairParts[1];

    // DecodeURIComponent the key and value
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);

    // Add the key-value pair to the object
    params[key] = value;
  }

  // Return the object
  return params;
};
