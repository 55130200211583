import { Box, CircularProgress } from "@mui/material";
import React from "react";

const MuiLoader = ({ size, sx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        padding: "20px",
        width: "100%",
        ...sx,
      }}
    >
      <CircularProgress size={size ? size : 24} />
    </Box>
  );
};

export default MuiLoader;
