import { Typography } from "@mui/material";
import { isNil } from "lodash";
import React from "react";
import { isMobile } from "react-device-detect";

const CalendarInviteTitleText = ({ color, children, variant, colorVariant, sx, fontWeight, fontSize }) => {
  if (isNil(children)) {
    return null;
  }

  const colorVariants = {
    alt: "#717171",
  };

  const titleColor = colorVariant ? colorVariants[colorVariant] : "var(--primary)";

  const sharedStyles = {
    color: titleColor,
  };

  const variants = {
    h1: {
      fontSize: "1.6rem",
      fontWeight: 800,
      lineHeight: "2.4rem",
      fontFamily: "var(--inter) !important",
    },
    h2: {
      fontSize: isMobile ? "1.1rem" : "1.35rem",
      fontWeight: 700,
      lineHeight: 1.567,
      fontFamily: "var(--inter) !important",
    },
    h3: {
      fontSize: "1.05rem",
      fontWeight: 400,
      lineHeight: 1.235,
      fontFamily: "var(--inter) !important",
    },
    h4: {
      fontSize: ".82rem",
      fontWeight: 500,
      lineHeight: 1.135,
      fontFamily: "var(--inter) !important",
    },
  };

  const variantStyles = isNil(variant) ? variants["h1"] : variants[variant];

  return (
    <Typography
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      variant={variant ? variant : "h1"}
      sx={{ ...sharedStyles, ...variantStyles, ...sx }}
    >
      {children}
    </Typography>
  );
};

export default CalendarInviteTitleText;
