const _ = require("lodash");
export const checkIfInputHasChanges = (
  currentValue,
  comparingValueInit,
  object,
  pathName
) => {
  let comparingValue;
  // console.log(
  //   `currentValue: ${currentValue}   |   comparingValue: ${comparingValueInit}`
  // );
  // console.log(comparingValueInit);

  // console.log("comparingValueInit", comparingValueInit);
  // console.log("object", object);
  if (object !== undefined) {
    comparingValue = _.get(object, pathName);
  } else {
    comparingValue = comparingValueInit;
  }

  if (
    currentValue == comparingValue ||
    currentValue === "" ||
    currentValue === undefined
  ) {
    // console.log("no changes");
    return false;
  } else {
    // console.log("changes");
    return true;
  }
};
