import { Avatar } from "@mui/material";
import { isNil } from "lodash";
import React from "react";

function getInitials(inputString) {
  if (isNil(inputString)) {
    return;
  }
  const words = inputString.split(" ");
  const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
  return initials;
}

const ProfileImage = ({ src, name }) => {
  const userInitials = getInitials(name);

  if (isNil(src)) {
    return (
      <Avatar sx={{ width: 72, height: 72, border: "2px solid #ffffff", margin: "20px auto", fontSize: "1.4rem" }}>
        {userInitials}
      </Avatar>
    );
  }

  return (
    <Avatar sx={{ width: 72, height: 72, border: "2px solid #ffffff", margin: "20px auto" }} alt={name} src={src} />
  );
};

export default ProfileImage;
