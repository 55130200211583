import { fileDocumentSchema } from "../../forms/_formQuestions/_formSchema/fileDocumentSchema";

const _ = require("lodash");

export const createSelectItemsDocType = (docGroup) => {
  // console.log("createSelectItemsDocTypes attempt - ", docGroup);

  if (docGroup === undefined) {
    return [];
  }
  let listOptions = [];

  if (fileDocumentSchema[docGroup] === undefined) {
    return [];
  }
  // if (employees === undefined || employees === null) {
  //   return [];
  // }
  let obj = fileDocumentSchema[docGroup].types;

  _.forOwn(obj, (g) => {
    listOptions.push({
      name: g.name,
      label: g.label,
    });
  });

  listOptions.sort((a, b) => a.label.localeCompare(b.name));
  // console.log("list", listOptions);
  // let sorted = listOptions.sort((a, b) => a.label.localeCompare(b.name));
  // console.log("sorted", sorted);

  return listOptions;
};
