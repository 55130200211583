import { isNil } from "lodash";
import React from "react";
import { useFormState } from "react-final-form";
import FinalFormSelectField from "../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../components/form/Fields/FinalFormTextField";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import { yesNo } from "../../_formQuestions/selectOptions";

// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepOne.css";

const BorrowerLoanAppExperience = (props) => {
  const { values } = useFormState();

  const VALIDATOR_EXPERIENCE = (value) => {
    if (!isNil(value)) {
      return undefined;
    }
    const incomeIsFilledOut = values.hasExperienceWithIncomeProducingProperties === "Yes" ? true : false;
    const fixIsFilledOut = values.hasExperienceWithFixFlips === "Yes" ? true : false;
    const gucIsFilledOut = values.hasExperienceWithGroundUpConstruction === "Yes" ? true : false;

    return incomeIsFilledOut || fixIsFilledOut || gucIsFilledOut ? undefined : "Required";
  };
  return (
    <>
      <FormGroup label="Income Producing Properites" noMargin>
        <MuiGrid>
          {/* /1/ */}
          <GridItem mobile size={values.hasExperienceWithIncomeProducingProperties === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              noMargin
              name={"hasExperienceWithIncomeProducingProperties"}
              label="Do you have experience?"
              items={yesNo}
              validate={VALIDATOR_EXPERIENCE}
            />
          </GridItem>
          {values.hasExperienceWithIncomeProducingProperties === "Yes" && (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  name={"numOfIncomeProducingPropertiesWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Fix And Flip Properties">
        <MuiGrid>
          <GridItem mobile size={values.hasExperienceWithFixFlips === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperienceWithFixFlips"}
              label="Do you have experience?"
              validate={VALIDATOR_EXPERIENCE}
              items={yesNo}
            />
          </GridItem>
          {values.hasExperienceWithFixFlips === "Yes" && (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  name={"numOfFixFlipsWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Ground Up Construction Projects">
        <MuiGrid>
          <GridItem mobile size={values.hasExperienceWithGroundUpConstruction === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperienceWithGroundUpConstruction"}
              label="Do you have experience?"
              validate={VALIDATOR_EXPERIENCE}
              items={yesNo}
            />
          </GridItem>

          {values.hasExperienceWithGroundUpConstruction === "Yes" && (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  name={"numOfGroundUpConstructionProjectsWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default BorrowerLoanAppExperience;
