export const fileDocumentSchema = {
  titleDocs: {
    name: "titleDocs",
    label: "Title Docs",
    types: {
      preliminaryTitle: {
        name: "preliminaryTitle",
        label: "Preliminary Title",
      },
      taxCerts: { name: "taxCerts", label: "Tax Certs" },
      titleDocsOther: { name: "titleDocsOther", label: "Title Docs - Other" },
    },
  },
  constructionDocuments: {
    name: "constructionDocuments",
    label: "Construction Documents",
    types: {
      scopeOfWork: { name: "scopeOfWork", label: "Scope Of Work" },
      permits: { name: "permits", label: "Permits " },
      plans: { name: "plans", label: "Plans " },
      constructionDocumentsOther: {
        name: "constructionDocumentsOther",
        label: "Construction Documents - Other",
      },
    },
  },

  thirdPartyReports: {
    name: "thirdPartyReports",
    label: "Third Party Reports",
    types: {
      feasibilityReport: {
        name: "feasibilityReport",
        label: "Feasibility Report",
      },
      environmentalReport: {
        name: "environmentalReport",
        label: "Environmental Report",
      },
      thirdPartyReportsOther: {
        name: "thirdPartyReportsOther",
        label: "Third Party Reports - Other",
      },
    },
  },
  insuranceDocs: {
    name: "insuranceDocs",
    label: "Insurance Docs",
    types: {
      insuranceInvoice: {
        name: "insuranceInvoice",
        label: "Insurance Invoice",
      },
      insuranceBinder: { name: "insuranceBinder", label: "Insurance Binder" },
      hoaMasterInsurancePolicy: {
        name: "hoaMasterInsurancePolicy",
        label: "Hoa Master Insurance Policy",
      },
      insuranceFinalPaidInFullReceipt: {
        name: "insuranceFinalPaidInFullReceipt",
        label: "Insurance Final Paid Receipt",
      },
      floodInsurance: { name: "floodInsurance", label: "Flood Insurance" },
      insuranceDocsOther: {
        name: "insuranceDocsOther",
        label: "Insurance Docs - Other",
      },
    },
  },
  liquidity: {
    name: "liquidity",
    label: "Liquidity",
    types: {
      bankStatement: { name: "bankStatement", label: "Bank Statement" },
      otherAccountTypeStatement: {
        name: "otherAccountTypeStatement",
        label: "Other Account Type Statement",
      },
      giftLetter: { name: "giftLetter", label: "Gift Letter" },
      liquidityOther: { name: "liquidityOther", label: "Liquidity - Other" },
    },
  },

  entityDocs: {
    name: "entityDocs",
    label: "Entity Docs",
    types: {
      ein: { name: "ein", label: "EIN" },
      articlesOfOrganization: {
        name: "articlesOfOrganization",
        label: "Articles Of Organization",
      },
      operatingAgreement: {
        name: "operatingAgreement",
        label: "Operating Agreement",
      },
      cogs: { name: "cogs", label: "COGS" },
      foreignEntityDocs: {
        name: "foreignEntityDocs",
        label: "Foreign Entity Docs",
      },
      entityDocsOther: {
        name: "entityDocsOther",
        label: "Entity Docs - Other",
      },
    },
  },
  loeAndExceptions: {
    name: "loeAndExceptions",
    label: "Letter of Explanation & Exceptions",
    types: {
      backgroundLOE: { name: "backgroundLOE", label: "Background LOE" },
      creditReportLOE: { name: "creditReportLOE", label: "CreditReport LOE" },
      liquidityLOE: { name: "liquidityLOE", label: "Liquidity LOE" },
      akaLOE: { name: "akaLOE", label: "Aka Letter" },
      loeOther: { name: "loeOther", label: "Letter of Explanation - Other" },
    },
  },
  appraisal: {
    name: "appraisal",
    label: "Appraisal",
    types: {
      appraisalPDF: { name: "appraisalPDF", label: "Appraisal PDF" },
      appraisalXML: { name: "appraisalXML", label: "Appraisal XML" },
      secondaryAppraisal: {
        name: "secondaryAppraisal",
        label: "Secondary Appraisal",
      },
      appraisalAmendment: {
        name: "appraisalAmendment",
        label: "Appraisal Amendment",
      },
      appraisalOther: { name: "appraisalOther", label: "Appraisal - Other" },
    },
  },
  closingPackage: {
    name: "closingPackage",
    label: "Closing Package",
    types: {
      finalHud: { name: "finalHud", label: "FinalHud" },
      assignmentOfMortgage: {
        name: "assignmentOfMortgage",
        label: "Assignment Of Mortgage",
      },
      allonge: { name: "allonge", label: "Allonge" },
      assignmentOfLeasesAndRents: {
        name: "assignmentOfLeasesAndRents",
        label: "Assignment Of Leases & Rents",
      },
      businessPurposeAffadavit: {
        name: "businessPurposeAffadavit",
        label: "Business Purpose Affadavit",
      },
      cema: { name: "cema", label: "Cema" },
      closingPackage: { name: "closingPackage", label: "Closing Package" },
      loanAgreement: { name: "loanAgreement", label: "Loan Agreement" },
      finalTitlePolicy: {
        name: "finalTitlePolicy",
        label: "Final Title Policy",
      },
      guaranty: { name: "guaranty", label: "Guaranty" },
      mortgage: { name: "mortgage", label: "Mortgage" },
      note: { name: "note", label: "Note" },
      recordedDocs: { name: "recordedDocs", label: "Recorded Documents" },
      closingPackageOther: {
        name: "closingPackageOther",
        label: "Closing Package - Other",
      },
    },
  },
  questionnaires: {
    name: "questionnaires",
    label: "Questionnaires",
    types: {
      condoQuestionnaire: {
        name: "condoQuestionnaire",
        label: "Condo Questionnaire",
      },
      managementQuestionnaire: {
        name: "managementQuestionnaire",
        label: "Management Questionnaire",
      },
      questionnairesOther: {
        name: "questionnairesOther",
        label: "Questionnaires - Other",
      },
    },
  },
  leasesAndRents: {
    name: "leasesAndRents",
    label: "Leases & Rents",
    types: {
      proofOfRentReceived: {
        name: "proofOfRentReceived",
        label: "Proof Of Rent Received",
      },
      unitLeaseDocs: { name: "unitLeaseDocs", label: "Unit Rental Lease" },
      leasesAndRentsOther: {
        name: "leasesAndRentsOther",
        label: "Leases & Rents - Other",
      },
    },
  },
  propertyDocuments: {
    name: "propertyDocuments",
    label: "Property Documents",
    types: {
      hoaDues: { name: "hoaDues", label: "Hoa Dues" },
      payoffLetter: { name: "payoffLetter", label: "Payoff Letter" },
      floodCert: { name: "floodCert", label: "Flood Certificate" },
      purchaseContract: {
        name: "purchaseContract",
        label: "Purchase Contract",
      },
      purchaseHud: { name: "purchaseHud", label: "Purchase Hud" },
      vom: { name: "vom", label: "Verification of Mortgage (VOM)" },
      propertyManagementAgreement: {
        name: "propertyManagementAgreement",
        label: "Property Management Agreement",
      },
      propertyDocumentsOther: {
        name: "propertyDocumentsOther",
        label: "Property Documents - Other",
      },
    },
  },
  termsAndSizer: {
    name: "termsAndSizer",
    label: "Terms & Sizer",
    types: {
      sizer: { name: "sizer", label: "Sizer" },
      moneyInSizer: { name: "moneyInSizer", label: "Money-In Sizer" },
      termSheet: { name: "termSheet", label: "TermSheet" },
      preliminaryHud: { name: "preliminaryHud", label: "Preliminary Hud" },
      termsAndSizerOther: {
        name: "termsAndSizerOther",
        label: "Terms & Sizer - Other",
      },
    },
  },
  moneyIn: {
    name: "moneyIn",
    label: "Money In",
    types: {
      moneyInSizer: { name: "moneyInSizer", label: "Money-In Sizer" },
      scopeOfWork: { name: "moneyInScopeOfWork", label: "Scope of Work" },
      purchaseContract: {
        name: "moneyInPurchaseContract",
        label: "Purchase Contract",
      },
    },
  },
  borrowerDocs: {
    name: "borrowerDocs",
    label: "Borrower Documents",
    types: {
      photoId: { name: "photoId", label: "Photo Identification" },
      backgroundReport: {
        name: "backgroundReport",
        label: "Background Report",
      },
      creditReport: { name: "creditReport", label: "Credit Report" },
      creditAuth: { name: "creditAuth", label: "Credit Authorization" },
      borrowerExperience: {
        name: "borrowerExperience",
        label: "Borrower Experience Docs",
      },
      loanApplication: { name: "loanApplication", label: "Loan Application" },
      ofacReport: { name: "ofacReport", label: "OFAC Report" },
      borrowerDocsOther: {
        name: "borrowerDocsOther",
        label: "Borrower Documents - Other",
      },
    },
  },
  noCategory: {
    name: "noCategory",
    label: "No Category",
    types: {
      noCategory: { name: "noCategory", label: "No Category" },
    },
  },
  borrowerUploaded: {
    name: "borrowerUploadedDocuments",
    label: "Uploaded By Borrower",
    types: {
      borrowerUploaded: {
        name: "borrowerUploadedDocuments",
        label: "Uploaded By Borrower",
      },
    },
  },
};
