import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useFormState } from "react-final-form";
import { getNumberFromArrayLength } from "../../../helpers/getNumberFromArrayLength";
import { getObjectValueFromStringPath } from "../../../helpers/getObjectValueFromStringPath";
import "./MultipleItemCard.css";

const MultipleItemCard = ({
  index,
  title,
  children,
  onRemove,
  unitsPathName,
  indexTitle,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { values } = useFormState();
  const handleAccordianClick = (data) => {
    console.log("Change");
    if (isOpen) setIsOpen(false);
    if (!isOpen) setIsOpen(true);
  };
  return (
    <div
      className={`multiple-item-card-outer-wrapper ${
        index >= 1 ? "additional" : null
      }`}
    >
      <article className="multiple-item-card">
        <Accordion
          id="all-fields-accordian"
          expanded={isOpen}
          // onChange={() => setIsEditing(false)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore
                sx={{ height: "20px", width: "20px" }}
                onClick={handleAccordianClick}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="multiple-item-card__header">
              <div className="header__title">
                <h3>{title}</h3>
                <span className="index-value">
                  {/* ({index + 1} of {totalItems}) */}({index + 1} of{" "}
                  {getNumberFromArrayLength(
                    getObjectValueFromStringPath(values, unitsPathName)
                  )}
                  )
                </span>
              </div>

              {/* <div className="header__item-index">
         
        </div> */}
              {index > 0 && (
                <button className="remove" type="button" onClick={onRemove}>
                  <span>Remove</span>
                </button>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="multiple-item-card__body">{children}</div>
          </AccordionDetails>
        </Accordion>
      </article>
    </div>
  );
};

export default MultipleItemCard;
