import { isNil } from "lodash";
import React from "react";
import { Box, Typography, Zoom } from "@mui/material";

const variants = {
  small: {
    ".icon": {
      fontWeight: 500,
      fontSize: ".9rem",
    },
    ".label": {
      color: " rgba(49, 49, 49, 0.75)",
      fontWeight: 400,
      fontSize: ".65rem",
    },
    ".value": {
      fontWeight: 500,
      fontSize: ".65rem",
    },
  },

  light: {
    ".icon": {
      fontWeight: 500,
      fontSize: ".9rem",
    },
    ".label": {
      color: " rgba(49, 49, 49, 0.6901960784)",
      fontWeight: 500,
    },
    ".value": {
      fontWeight: 600,
    },
  },
  default: {
    ".icon": {
      fontWeight: 500,
      fontSize: ".9rem",
    },
    ".label": {
      color: " rgba(49, 49, 49, 0.6901960784)",
      fontWeight: 500,
      fontSize: ".7rem",
    },
    ".value": {
      fontWeight: 700,
      fontSize: ".7rem",
    },
  },
};

const CalendarInviteLabelAndValue = ({ label, column, value, icon, variant, delay, sx }) => {
  /**
   * variants: 'column'
   */

  const gapAmount = isNil(column) ? "4px" : "0";
  const directionType = isNil(column) ? "row" : "column";
  const variantType = isNil(variant) ? "default" : variant;
  const styles = variants[variantType];
  return (
    <Zoom in={true} style={{ transitionDelay: delay }}>
      <Box
        display={"flex"}
        gap={gapAmount}
        sx={{
          flexDirection: directionType,
          ...styles,
          ...sx,
        }}
      >
        <Icon>{icon}</Icon>
        <Label>{label}</Label>
        <Value>{value}</Value>
      </Box>
    </Zoom>
  );
};

const Icon = ({ children, sx }) => {
  if (isNil(children)) {
    return null;
  }
  return children;
};

const Label = ({ children, sx }) => {
  if (isNil(children)) {
    return null;
  }
  return (
    <Typography className="label" sx={{ textAlign: "center", whiteSpace: "nowrap" }}>
      {children}
    </Typography>
  );
};

const Value = ({ children, sx }) => {
  if (isNil(children)) {
    return null;
  }
  return (
    <Typography className="value" sx={{ textAlign: "center", whiteSpace: "nowrap" }}>
      {children}
    </Typography>
  );
};

export default CalendarInviteLabelAndValue;
