import { Box, Typography } from "@mui/material";
import React from "react";

const CalendarSectionWrapper = ({ title, children, sx }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", ...sx }}>
      <Typography
        sx={{
          // borderBottom: "var(--border)",
          width: "100%",

          fontWeight: 600,
          textAlign: "left",
          fontSize: "13px",
          marginBottom: "6px",
          color: "rgb(51, 71, 91)",
          fontFamily: "var(--inter) !important",
        }}
        variant="h3"
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};
export default CalendarSectionWrapper;
