import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useFormState } from "react-final-form";
import { getNumberFromArrayLength } from "../../../helpers/getNumberFromArrayLength";
import { getObjectValueFromStringPath } from "../../../helpers/getObjectValueFromStringPath";
import "./PropertyUnitCard.css";

const PropertyUnitCard = ({
  index,
  title,
  children,
  onRemove,
  totalItems,
  indexTitle,
  unitsPathName,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { values } = useFormState();
  const handleAccordianClick = (data) => {
    console.log("Change");
    if (isOpen) setIsOpen(false);
    if (!isOpen) setIsOpen(true);
  };
  console.log(
    "unitsPathName ---------------------",
    unitsPathName,
    getNumberFromArrayLength(
      getObjectValueFromStringPath(values, unitsPathName)
    )
  );
  return (
    <div
      id="property-unit"
      className={`multiple-unit-card-item ${index >= 1 ? "additional" : null}`}
    >
      <article className="multiple-item-card">
        <Accordion
          id="property-unit-all-fields-accordian"
          expanded={isOpen}
          // onChange={() => setIsEditing(false)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMore
                sx={{ height: "20px", width: "20px" }}
                onClick={handleAccordianClick}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="multiple-item-card__header">
              <div className="header__title">
                <h4>
                  <span>{title}</span>
                  <span className="index-value">
                    ({index + 1} of{" "}
                    {getNumberFromArrayLength(
                      getObjectValueFromStringPath(values, unitsPathName)
                    )}
                    )
                  </span>
                </h4>
              </div>

              {/* <div className="header__item-index">
         
        </div> */}
              {index > 0 && (
                <button className="remove" type="button" onClick={onRemove}>
                  <span>Remove</span>
                </button>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="multiple-item-card__body">{children}</div>
          </AccordionDetails>
        </Accordion>
      </article>
    </div>
  );
};

export default PropertyUnitCard;
