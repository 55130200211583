import { useDispatch } from "react-redux";
import { mapNestedObjectFormData } from "../helpers/mapObjectFormData";
import { globalAlertSuccess } from "../redux/actions/globalAlertActions";

import { useCallback } from "react";
import { useAxiosHook } from "./useAxiosHook";

export const useFormsHook = () => {
  const { axiosPostIsLoading, axiosGetIsLoading, axiosError, axiosGetRequest, axiosPostRequest } = useAxiosHook();

  const isLoading = axiosPostIsLoading;
  const error = axiosError;
  const dispatch = useDispatch();

  const submitAuctionForm = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailFn,
    onFailMsg,
  }) => {
    /**Create Broker Scenario **/
    console.log("payload", payload);
    const endpoint = `auction_borrower_preapproval?actionCategory=auctionPreApproval `;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Submit Auction Form",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailFn: onFailFn,
      onFailMsg: "Could not submit Auction Form",
    };

    // onRequestSuccess(DUMM);
    await axiosPostRequest(params);
  };

  const submitCreditAuth = async ({
    payload, //required
    name,
    onSuccessFn,
    onFailMsg,
  }) => {
    /** Create Borrower Scenario **/
    const endpoint = `ironlinc_app_submissions?actionCategory=creditAuth`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Credit Auth",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not submit Credit Authorization",
    };
    await axiosPostRequest(params);
  };

  const submitLoanApplication = async ({
    payload, //required
    onSuccessFn,
  }) => {
    /** Create Borrower Scenario **/
    const endpoint = `ironlinc_app_submissions?actionCategory=application`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Submiy Loan Application",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not submit loan application",
    };
    await axiosPostRequest(params);
  };

  const submitRequestToBeContacted = async ({
    payload, //required
    onSuccessFn,
  }) => {
    /** Create Borrower Scenario **/
    const endpoint = `ironlinc_app_submissions?actionCategory=requestContact`;

    const onRequestSuccess = (data) => {
      dispatch(globalAlertSuccess("Submission Successful"));
      if (onSuccessFn) {
        onSuccessFn(data);
      }
      // setLoading(null);
    };

    const params = {
      name: "Request Contact",
      path: endpoint,
      payload: mapNestedObjectFormData(payload),
      onSuccessFn: onRequestSuccess,
      onFailMsg: "Could not submit request to be contacted",
    };
    await axiosPostRequest(params);
  };

  return {
    error,
    isLoading,
    submitCreditAuth,
    submitLoanApplication,
    submitAuctionForm,
    submitRequestToBeContacted,
  };
};
