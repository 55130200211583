export const getDayNumFromDate = (day) => {
  // If the input is a string, parse it into a Date object
  const date = typeof day === "string" ? new Date(day) : day;

  // Check if the input date is valid
  if (isNaN(date)) {
    return "Invalid Date";
  }

  // Get the day of the month (1-31)
  const dayOfMonth = date.getDate();

  return dayOfMonth;
};
