import React, { useEffect, useState } from "react";
import { useFormState } from "react-final-form";

const DependentFields = (props) => {
  const { values } = useFormState();
  const [show, setShow] = useState(false);
  // console.log(props.conditions[0], props.conditions[1]);
  // console.log(values[`${props.property}`]);
  // let conditions = values[`${props.conditions[0]}`] === props.conditions[1];

  if (props.notEqual) {
    if (props.value) {
      return (
        <>
          {values[`${props.name}`] === props.value ? null : (
            <> {props.children}</>
          )}
        </>
      );
    }
  }

  if (props.value) {
    return values[`${props.name}`] !== props.value ? null : props.children;
  }

  if (props.values) {
    let returnVal = null;
    props.values.map((val) => {
      // console.log("val", values[`${props.name}`], val);
      if (values[`${props.name}`] === val) {
        // console.log("true");
        returnVal = props.children;
      }
    });
    // console.log("null");
    return returnVal;
  }

  if (props.multipleValues) {
    let returnVal = null;
    props.multipleValues.map((item) => {
      let key = item[0];
      let val = item[1];
      if (values[`${key}`] === val) {
        returnVal = props.children;
      }
    });
    // console.log("null");
    return returnVal;
  }
};

export default DependentFields;
