import BorrowerCreditAuthBorrowerInfo from "./BorrowerCreditAuthBorrowerInfo";

import BorrowerCreditAuthBorrowerBackground from "./BorrowerCreditAuthBorrowerBackground";
import BorrowerCreditAuthConfirmation from "./BorrowerCreditAuthConfirmation";

export const borrowerCreditAuthFormSteps = [
  {
    stepName: "Your Information",
    stepComponent: <BorrowerCreditAuthBorrowerInfo />,
    // pageHeader: "Personal & Contact Information",
  },

  {
    stepName: "Identity & Background",
    stepComponent: <BorrowerCreditAuthBorrowerBackground />,
    // pageHeader: "Background Information",
  },
  {
    stepName: "Confirmation",
    stepComponent: <BorrowerCreditAuthConfirmation />,
    // pageHeader: "Confirmation",
  },
];
